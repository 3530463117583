@font-face {
font-family: '__Itc_1629c1';
src: url(/_next/static/media/c90ead9fb2df248c-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__Itc_1629c1';
src: url(/_next/static/media/6a40e6d7f1060386-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__Itc_1629c1';
src: url(/_next/static/media/406074ea8b678cfd-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__Itc_Fallback_1629c1';src: local("Arial");ascent-override: 67.38%;descent-override: 23.67%;line-gap-override: 18.21%;size-adjust: 109.83%
}.__className_1629c1 {font-family: '__Itc_1629c1', '__Itc_Fallback_1629c1'
}

@font-face {
font-family: '__Nunito_7c07d8';
src: url(/_next/static/media/2ce32c88f66f838c-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__Nunito_7c07d8';
src: url(/_next/static/media/69bd3a1a95631d2d-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__Nunito_7c07d8';
src: url(/_next/static/media/f322e19764d32714-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__Nunito_Fallback_7c07d8';src: local("Arial");ascent-override: 88.24%;descent-override: 30.81%;line-gap-override: 0.00%;size-adjust: 114.57%
}.__className_7c07d8 {font-family: '__Nunito_7c07d8', '__Nunito_Fallback_7c07d8'
}

